import React from "react";

function BellIcon() {
	return (
		<svg className="bell-icon" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
			<path fill="var(--color, #fff)" d="M31 52h8v4h-8zm19-6V32c0-7.604-5.664-13.871-13-14.851V14h-3.937v3.139C25.696 18.091 20 24.374 20 32v14h-4v4h37v-4h-3zm-4 0H24V32c0-6.065 4.935-11 11-11s11 4.935 11 11v14z"></path>
		</svg>
	);
}

export default BellIcon;