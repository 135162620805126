import React from 'react';
import useCatalog from '../../../hooks/useCatalog';
import useMissions from '../../../hooks/useMissions';
import { CDN_BASE } from '../../../Constants';
import CloverIcon from '../../icons/CloverIcon';
import StarIcon from '../../icons/StarIcon';

export default function Reward({ reward }: { reward: { dataType:string, dataKey:string, dataVal:string | number } }) {
	const { items } = useCatalog();
	const missions = useMissions();
	
	let content = null;
	switch (reward.dataType) {
		case 'item_grant':
			const item = items.find(i => i.itemId === reward.dataVal);
			if (item) {
				content = (
					<>
						<img src={item.playfab.ItemImageUrl} alt={item.playfab.DisplayName} />
						<div className="name">{item.playfab.DisplayName}</div>
					</>
				);
			}
			break;
		case 'mission_grant': 
			const mission = missions.find(i => i.itemId === reward.dataVal);
			if (mission) {
				content = (
					<>
						<img src={CDN_BASE + '/assets/quest.png' }alt={mission.playfab.DisplayName} />
						<div className="name">{mission.playfab.DisplayName}</div>
					</>
				);
			}
			break;
		case 'currency_grant': 
			content = (
				<>
					<img src={CDN_BASE + '/assets/coin-bag.png'} alt={reward.dataKey} />
					<div className="name">+{reward.dataVal} {reward.dataKey}</div>
				</>
			);
			break;
		case 'stat_change':
			const icons = {
				xp: <CloverIcon />,
				points: <StarIcon />
			}
			content = (
				<>
					{icons[reward.dataKey] || <div />}
					<div className="name">+{reward.dataVal} {reward.dataKey}</div>
				</>
			);
			break;
		default: break;
	}


	return (
		<div className="reward">
			{content}
		</div>
	);
}