import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { LoginMethods, setIsLoggingIn } from '../redux/env';
import { authPlayfab } from '../redux/playfab';
import { guid } from '../utils/guid';

export default function useLogin() {
	const dispatch = useAppDispatch();
	const { isLoggingIn, loggingMethod } = useAppSelector(state => state.env);

	async function auth(str:string, method:LoginMethods) {
		dispatch(setIsLoggingIn(true));
		await dispatch(authPlayfab({ userId: str })).then((resp) => {
			if (resp.error) {
				console.error(resp.error);
			}
		})
	}

	function OnClickLoginWithUser(customId:string) {
		auth(customId, LoginMethods.CUSTOM_ID);
	}

	function OnClickNewUser() {
		auth(guid(), LoginMethods.CUSTOM_ID);
	}

	return {
		loggingMethod,
		IsLoggingIn: isLoggingIn,

		OnClickLoginWithUser,
		OnClickNewUser,
	};
}