import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { IPlayerStatistics } from '../redux/statistics';
import { getLeaderboardAroundPlayer } from '../redux/leaderBoardAroundPlayer';
import usePlayer from './usePlayer';

export default function useLeaderboardAroundPlayer(
	statisticName: keyof IPlayerStatistics,
	forceUpdate = false
) {
	const leaderBoardAroundPlayer = useAppSelector(
		(state) => state.leaderBoardAroundPlayer[statisticName]
	);

	const { playerId } = usePlayer();

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (playerId && (!leaderBoardAroundPlayer || forceUpdate)) {
			dispatch(getLeaderboardAroundPlayer({ StatisticName: statisticName }));
		}
	}, [statisticName, playerId, dispatch, leaderBoardAroundPlayer, forceUpdate]);

	return {
		updateLeaderboardAroundPlayer: () =>
			dispatch(getLeaderboardAroundPlayer({ StatisticName: statisticName })),
		leaderBoardAroundPlayer,
	};
}
