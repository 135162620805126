import { createSlice } from '@reduxjs/toolkit';
import { getXrApi } from '../api/apiBridge';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';
import xrAction from './async/xrAction';

export const getGlobalVariables = createDebouncedAsyncAction(
	'playfab-xr/getGlobalVariables',
	xrAction(() => {
		return getXrApi().Client.GetGlobalVariable();
	}),
);

const initialState = {
	isLoaded: false,
	missionsActive: 0 as number | string,
	badgesActive: 0 as number | string,
	leaderboardActive: 0 as number | string,
	CompoundStatsConfig: {} as ({ source: { name: string, mapping: number[] }, target: { name: string, mapping: number[] } }[] | null),
};

const globalVariables = createSlice({
	name: 'globalVariables',
	reducers: {
		setGlobalVariable(state, action) {
			state[action.payload.key] = action.payload.value;
			if (action.payload.key.includes('Active')) {
				state[action.payload.key] = parseInt(action.payload.value);
			}
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getGlobalVariables.actions.fulfilled, (state, action) => {
			return {
				...state,
				isLoaded: true,
				...action.payload.data.GlobalVariables.reduce((acc, curr) => {
					acc[curr.dataKey] = curr.dataVal;
					if (curr.dataKey.includes('Active')) {
						acc[curr.dataKey] = parseInt(acc[curr.dataKey]);
					}
					return acc;
				}, {} as Record<string, any>),
			};
		});
	},
	initialState,
});

export default globalVariables;

export const { setGlobalVariable } = globalVariables.actions;