import React, { memo } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { ROUTES } from './Constants';
import '../css/index.scss';
import usePlayFab from './hooks/usePlayFab';
import useRealtime from './hooks/useRealtime';
import Debug from './components/Debug';
import Login from './components/routes/Login';
import Notifications from './components/ui/Notifications';
import usePlayerStatistics from './hooks/usePlayerStatistics';
import Menu from './components/Menu';
import Profile from './components/routes/Profile';
import Missions from './components/routes/Missions';
import Rankings from './components/routes/Rankings';
import { useAppSelector } from './redux/config/store';
import TopSection from './components/TopSection';
import useInventoryExpiration from './hooks/useInventoryExpiration';
import BadgesPage from './components/routes/BadgesPage';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

function App() {
	/*
	* Initialize PlayFab XR connection (once logged in, we fetch all the player data, you should)
	* only use this hook once, and only at the root of your app.
	*/
	const { playerId, isLoaded } = usePlayFab();
	const { hasLoaded } = usePlayerStatistics();

	useInventoryExpiration();

	// Connect to realtime if the service is active in this app
	useRealtime();

	const isLogin = useMatch(ROUTES.ROOT + ROUTES.LOGIN);
	const showLogin = !playerId && !isLogin;
	const showMenu = !isLogin;

	const lang = useAppSelector((state) => state.env.Lang);

	return (
		<div className={lang + (isSafari ? ' safari' : '')}>
			{showLogin && <Navigate to={ROUTES.LOGIN} replace={true} />}
			<Debug />
			
			<div className="page">
				<TopSection />

				<Routes>
					<Route path={ROUTES.ROOT}>
						<Route index element={isLoaded && hasLoaded && <Profile />} />
						<Route path={ROUTES.LOGIN} element={<Login />} />
						<Route path={ROUTES.MISSIONS} element={<Missions />} />
						<Route path={ROUTES.BADGES} element={<BadgesPage />} />
						<Route path={ROUTES.RANKINGS} element={<Rankings />} />
					</Route>
				</Routes>
			</div>
			{showMenu && <Menu />}
			<Notifications />
		</div>
	);
}

export default memo(App);
