import React from "react";

function MedalIcon() {
  return (
    <svg className="medal-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 296.193 296.193">
      <path fill="var(--color, #fff)" d="M213.764 95.654V10c0-5.523-4.145-10-9.667-10h-49.333v75.687c22 1.24 42 8.135 59 19.967zM139.764 0H91.097c-5.522 0-10.333 4.477-10.333 10v86.341c18-12.063 38-19.171 59-20.594V0zm8.332 90.475c-56.807 0-102.857 46.051-102.857 102.859S91.29 296.193 148.096 296.193c56.809 0 102.859-46.051 102.859-102.859S204.904 90.475 148.096 90.475zm49.411 118.904l6.593 38.447a15.002 15.002 0 01-14.786 17.535c-2.387 0-4.784-.569-6.979-1.724l-34.522-18.151-34.527 18.152a14.998 14.998 0 01-15.798-1.142 15 15 0 01-5.967-14.671l6.597-38.446-27.934-27.226a15.001 15.001 0 018.313-25.587l38.601-5.607 17.264-34.979a15 15 0 0126.902 0l17.263 34.98 38.599 5.607a14.998 14.998 0 018.313 25.585l-27.932 27.227z"></path>
      <path fill="var(--color, #fff)" d="M147.813 228.539l41.502 21.822-7.925-46.219 33.579-32.731-46.403-6.741-20.753-42.051-20.754 42.051-46.406 6.741 33.581 32.731-7.929 46.219z"></path>
    </svg>
  );
}

export default MedalIcon;