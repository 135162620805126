import React, { useEffect } from "react";
import useCatalog from '../../hooks/useCatalog';
import useInventory from '../../hooks/useInventory';
import { CDN_BASE, ITEM_CLASSES } from '../../Constants';
import { useNavigate } from "react-router-dom";
import useGlobalVariables from "../../hooks/useGlobalVariables";

function useBadges() {
	const { items } = useCatalog();
	const badges = items.filter(item => item.type.title === ITEM_CLASSES.BADGE);
	const inventory = useInventory();

	return badges.map(badge => {
		const owned = inventory.find(item => item.itemId === badge.itemId);

		return {
			...badge,
			isInInventory: !!owned,
		} as Badge;
	});
}

export default function BadgesPage() {
	const badges = useBadges();

	const { badgesActive } = useGlobalVariables();
	const navigate = useNavigate();

	useEffect(() => {
		if (badgesActive !== 1) {
			navigate('/');
		}
	}, [badgesActive, navigate]);

	return (
		<div className="page-content badges">
			<div className="section-title">Badges</div>

			<div className="list">
				{badges.map(badge => (
					<div className={`badge ${badge.isInInventory ? 'owned' : ''}`} key={badge.id}>
						<div className="icon">
							<img src={badge.playfab.ItemImageUrl || CDN_BASE + 'assets/medal.png'} alt="" />
						</div>
						<div className="name">{badge.playfab.DisplayName}</div>
					</div>
				))}
			</div>
		</div>
	)
}