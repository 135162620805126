import React, { useEffect } from 'react';
import useMissions from '../../hooks/useMissions';
import { MISSIONS_TYPES } from '../../Constants';
import SingleMission from './missions/SingleMission';
import { useNavigate } from 'react-router-dom';
import useGlobalVariables from '../../hooks/useGlobalVariables';

export default function Missions() {
	const missions = useMissions().filter(m => m.type.title !== MISSIONS_TYPES.REWARD_TRACK);

	const { missionsActive } = useGlobalVariables();
	const navigate = useNavigate();

	useEffect(() => {
		if (missionsActive !== 1) {
			navigate('/');
		}
	}, [missionsActive, navigate]);

	return (
		<>
			<div className="page-content missions">
				<div className="section-title">Missions</div>
				<div className="content">
					<div className="list">
						{missions.map(mission => (
							<SingleMission mission={mission} key={mission.itemId} />
						))}
						{(!missions || missions.length === 0) && (
							<div className="empty">No active mission</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}