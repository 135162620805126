import React, { useCallback, useEffect, useState } from "react";
import useLeaderboard from "../../hooks/useLeaderboard";
import usePlayer from "../../hooks/usePlayer";
import { CDN_BASE } from "../../Constants";
import useLeaderboardAroundPlayer from "../../hooks/useLeaderboardAroundPlayer";
import useTexts from "../../hooks/useTexts";
import { CSSTransition } from "react-transition-group";
import useInterval from "../../hooks/useInterval";
import useGlobalVariables from "../../hooks/useGlobalVariables";
import { useNavigate } from "react-router-dom";

export default function Rankings() {
	const { text, raw } = useTexts();
	
	const {
		leaderboard,
		updateLeaderboard,
	} = useLeaderboard('xp');

	const [once, setOnce] = useState(false);

	const {
		playerId,
		DisplayName,
	} = usePlayer();

	const {
		leaderBoardAroundPlayer,
		updateLeaderboardAroundPlayer,
	} = useLeaderboardAroundPlayer('xp');

	const playerEntry = leaderBoardAroundPlayer?.find(p => p.PlayFabId === playerId);
	
	const defaultAvatar = CDN_BASE + 'profile_default.jpg';

	const entries = leaderboard.slice(0, 3);
	const playerNotInLeaderboard = entries.find(p => p.PlayFabId === playerId) === undefined && playerEntry;

	const update = useCallback(() => {
		updateLeaderboard();
		updateLeaderboardAroundPlayer();
	}, [updateLeaderboard, updateLeaderboardAroundPlayer]);

	useEffect(() => {
		if (!once) {
			update();
			setOnce(true);
		}
	}, [once, update]);

	useInterval(() => {
		update();
	}, 60000);

	const { leaderboardActive } = useGlobalVariables();
	const navigate = useNavigate();

	useEffect(() => {
		if (leaderboardActive !== 1) {
			navigate('/');
		}
	}, [leaderboardActive, navigate]);

	return (
		<CSSTransition in={true} appear={true} timeout={1200} mountOnEnter unmountOnExit>
			<div className="page-content rankings">
				<div className="ctn">
					<div className="top">
						<div className="section-title">Leaderboard</div>
						<div className="description">Where do you stand in comparison to your colleagues,<br/><strong>Have you reached the top?</strong></div>
					</div>
					<div className="leaderboard">
						<div className="top-entries">
							{entries.map((entry, i) => (
								<div className={`top-entry position-${i + 1}`}>
									{i === 0 && <img src={CDN_BASE + 'assets/crown.png'} alt="" className="crown" /> }
									<div className="avatar">
										<img src={entry.Profile.AvatarUrl || defaultAvatar} width="100" height="100" alt="" />
									</div>
									<div className="rank">{entry.Position + 1}</div>
									<div className="content">
										<div className="name">{entry.DisplayName}</div>
										<div className="xp">{entry.StatValue} <span {...raw(text?.rankings?.xp)} /></div>
									</div>
								</div>
							))}
						</div>
						{
							playerNotInLeaderboard && (
								<div className="entry player" key={playerEntry.PlayFabId}>
									<div className="rank">{playerEntry.Position + 1 === 999 ? '-' : playerEntry.Position + 1}</div>
									<div className="data">
										<div className="avatar">
											<img src={playerEntry.Profile.AvatarUrl || defaultAvatar} width="100" height="100" alt="" />
										</div>
										<div className="content">
											<div className="name">{DisplayName}</div>
										</div>
										<div className="xp">{playerEntry.StatValue} <span {...raw(text?.rankings?.xp)} /></div>
									</div>
								</div>
							)
						}
					</div>
					<div className="rewards">
						<div className="left">
							<img src={CDN_BASE + 'assets/giftbox.png'} alt="" />
							Rewards
						</div>
						<div className="right">
							<div className="main">1st to 5th place: Special Reward</div>
							<div className="others">6th to 10th place: 499 points</div>
						</div>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}