import React from "react";

function StarIcon() {
	return (
		<svg
			className="star-icon"
			xmlns="http://www.w3.org/2000/svg"
			version="1.2"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path fill="var(--color, #fff)" d="M9.362 9.158l-5.268.584c-.19.023-.358.15-.421.343s0 .394.14.521c1.566 1.429 3.919 3.569 3.919 3.569-.002 0-.646 3.113-1.074 5.19a.496.496 0 00.734.534c1.844-1.048 4.606-2.624 4.606-2.624l4.604 2.625c.168.092.378.09.541-.029a.5.5 0 00.195-.505l-1.071-5.191 3.919-3.566a.499.499 0 00-.28-.865c-2.108-.236-5.269-.586-5.269-.586l-2.183-4.83a.499.499 0 00-.909 0l-2.183 4.83z"></path>
		</svg>
	);
}

export default StarIcon;