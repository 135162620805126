import React, { useEffect } from 'react';
import useLogin from '../../hooks/useLogin';
import usePlayer from '../../hooks/usePlayer';
import { Navigate } from 'react-router-dom';
import { ROUTES, STORE_NAME } from '../../Constants';
import { guid } from '../../utils/guid';
import { uniqueNamesGenerator, Config, names } from 'unique-names-generator';
import { useAppDispatch } from '../../redux/config/store';
import { updateDisplayName } from '../../redux/playfab';

const LS_KEY = STORE_NAME + '-uid';
export const saveUniqueIdentifier = (forceNew = false) => {
	let uid = localStorage.getItem(LS_KEY);
	
	if (!forceNew && uid) return uid;

	uid = guid();
	localStorage.setItem(LS_KEY, uid);

	return uid;
};

export const setUniqueIdentifier = (s) => localStorage.setItem(LS_KEY, s);
export const getUniqueIdentifier = () => localStorage.getItem(LS_KEY);


const config: Config = {
	dictionaries: [names]
}


export default function Login() {
	const {
		OnClickLoginWithUser,
		IsLoggingIn,
	} = useLogin();

	const { playerId, DisplayName } = usePlayer();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!playerId) {
			OnClickLoginWithUser(saveUniqueIdentifier());
		} else if (playerId && !DisplayName) {
			dispatch(updateDisplayName(uniqueNamesGenerator(config)));
		}
	}, [OnClickLoginWithUser, playerId, DisplayName, dispatch]);

	return (
		<div className="page login">
			{IsLoggingIn && <div className="loading">Logging in...</div>}
			{playerId && <Navigate to={ROUTES.ROOT} replace={true} />}
		</div>
	);
}