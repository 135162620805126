import React from "react";

function TrophyIcon() {
	return (
		<svg className="trophy-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40">
			<path
				fill="var(--color, #fff)"
				d="M3 11.72c0-2.04 1.24-3.8 3-4.58V4c-3.44.9-6 4-6 7.72 0 3.72 2.56 6.82 6 7.72V16.3c-1.76-.78-3-2.54-3-4.58zM18 36h-8v4h20v-4h-8v-4h-4v4zm22-24.28C40 8 37.44 4.9 34 4v3.14c1.76.78 3 2.54 3 4.58s-1.24 3.8-3 4.58v3.14c3.44-.9 6-4 6-7.72zM32 0H8v22l6 8h12l6-8V0zm-8.54 16.26l2.1 6.98-5.56-4.5-5.56 4.5 2.1-6.98L11 12h6.86L20 5.14 22.14 12H29l-5.54 4.26z"
			></path>
		</svg>
	);
}

export default TrophyIcon;