import React from 'react';

function ChevronIcon() {
	return (
		<svg className="chevron-icon" xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox='0 0 29 19' fill="none">
			<path
				fill="#D9D9D9"
				d="M0 4.243L4.243 0l14.142 14.142-4.243 4.243z"
			></path>
			<path
				fill="#D9D9D9"
				d="M24 0l4.243 4.243L14.1 18.385l-4.243-4.243z"
			></path>
		</svg>
	);
}

export default ChevronIcon;