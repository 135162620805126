import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { IPlayerStatistics } from '../redux/statistics';
import { getLeaderboard } from '../redux/leaderboard';
import usePlayer from './usePlayer';

const LEADERBOARD_LENGTH = 10;

export default function useLeaderboard(statisticName: keyof IPlayerStatistics, team:number = null, forceUpdate = false) {
	let leaderboard = useAppSelector(s => s.leaderboard[statisticName]);
	const count = leaderboard?.length || 0;

	const hasFewerThanMaxResultsBeforeFiltering = count < LEADERBOARD_LENGTH;

	if (leaderboard && team) {
		leaderboard = leaderboard.filter(l => l.Profile.Statistics.find(x => x.Name === 'team')?.Value === team);
	}

	leaderboard = leaderboard?.slice(0, LEADERBOARD_LENGTH);

	const {
		playerId,
	} = usePlayer();

	const dispatch = useAppDispatch();

	const fetchLeaderboard = useCallback((startPosition = 0) => {
		dispatch(getLeaderboard({ StatisticName: statisticName, MaxResultsCount: LEADERBOARD_LENGTH, StartPosition: startPosition }));
	}, [dispatch, statisticName]);

	useEffect(() => {
		if (playerId && (!leaderboard || forceUpdate) ) {
			fetchLeaderboard();
		}
	}, [statisticName, playerId, dispatch, leaderboard, forceUpdate, fetchLeaderboard]);

	useEffect(() => {
		if (leaderboard && leaderboard.length < LEADERBOARD_LENGTH && !hasFewerThanMaxResultsBeforeFiltering) {
			fetchLeaderboard(count);
		}
	}, [leaderboard, hasFewerThanMaxResultsBeforeFiltering, fetchLeaderboard, count]);

	return {
		updateLeaderboard: () => fetchLeaderboard(),
		leaderboard: leaderboard || [],
	};
}
