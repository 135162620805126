import React, { CSSProperties } from "react";
import useStats from "../../hooks/useStats";
import CircleProgress from "../ui/CircleProgress";

function KPI({ name, value, icon, accent }:{ name: string, value: number, icon: React.ReactNode, accent: string }) {
	return (
		<div className="kpi" style={{ '--accent': accent } as CSSProperties}>
			<div className="top">
				<div className="icon">{icon}</div>
				<div className="name">{name}</div>
			</div>
			<div className="progress">
				<CircleProgress progress={value / 100} />
				<div className="value">{value}%</div>
			</div>
		</div>
	);
}

export default function Profile() {
	const {
		kpiSales,
		kpiNBA,
		kpiNPS,
		kpiWKA,
	} = useStats();

	return (
		<div className="page-content profile">
			<div className="section-title">KPIs</div>
			<div className="kpis">
				<KPI name="Sales" value={kpiSales} icon={<div />} accent="239,61,20" />
				<KPI name="NBA" value={kpiNBA} icon={<div />} accent="52,70,107" />
				<KPI name="NPS" value={kpiNPS} icon={<div />} accent="146,22,36" />
				<KPI name="WKA" value={kpiWKA} icon={<div />} accent="52,105,70" />
			</div>
		</div>
	);
}