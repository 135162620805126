import React from 'react';
import Reward from './Reward';
import { CDN_BASE } from '../../../Constants';
import CheckmarkIcon from '../../icons/CheckmarkIcon';

export default function SingleMission({ mission }: { mission: IXRMissionItem }) {
	const expiration = Math.ceil((new Date(mission.playfab.Expiration || 0).valueOf() - Date.now()) / 1000 / 60 / 60 / 24);

	return (
		<div className="box single-mission">
			<div className="cols">
				<div className="left-col">
					<img src={mission.data.image as string || CDN_BASE + 'assets/quest.png'} alt="" />
					{mission.PlayerStatus?.IsComplete && <div className="complete">
						<CheckmarkIcon />
					</div>}
				</div>
				<div className="right-col">
					<div className="section-title">{mission.playfab.DisplayName}</div>
					{mission.playfab.Expiration && (<div className="expiration">Available for&nbsp;<strong>{expiration} days</strong></div>)}
					<div className="description">{mission.data.description as string}</div>
					<div className="rewards">
						<div className="list">
							{mission.rewards.map((reward, i) => (<Reward reward={reward} key={i} />))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}