import React, {  } from "react";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../Constants";
import ProfileIcon from "./icons/ProfileIcon";
import MissionIcon from "./icons/MissionIcon";
import TrophyIcon from "./icons/TrophyIcon";
import MedalIcon from "./icons/MedalIcon";
import useGlobalVariables from "../hooks/useGlobalVariables";

export default function Menu() {
	const {
		badgesActive,
		leaderboardActive,
		missionsActive,
	} = useGlobalVariables();

	return (
		<nav className="menu">
			<div className="items">
				<NavLink className="menu-item" to={ROUTES.ROOT} end>
					<span className="icon"><ProfileIcon /></span>
					Home
				</NavLink>
				{missionsActive === 1 && <NavLink className="menu-item" to={ROUTES.MISSIONS} end>
					<span className="icon"><MissionIcon /></span>
					Missions
				</NavLink>}
				{badgesActive === 1 && <NavLink className="menu-item" to={ROUTES.BADGES} end>
					<span className="icon"><MedalIcon /></span>
					Badges
				</NavLink>}
				{leaderboardActive === 1 && <NavLink className="menu-item" to={ROUTES.RANKINGS} end>
					<span className="icon"><TrophyIcon /></span>
					Leaderboard
				</NavLink>}
			</div>
		</nav>
	)
}
