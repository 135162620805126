import React from "react";
import Avatar from "./ui/Avatar";
import usePlayer from "../hooks/usePlayer";
import { NavLink, useMatch } from "react-router-dom";
import BellIcon from "./icons/BellIcon";
import TrophyIcon from "./icons/TrophyIcon";
import useStats from "../hooks/useStats";
import ChevronIcon from "./icons/ChevronIcon";
import StarIcon from "./icons/StarIcon";
import CloverIcon from "./icons/CloverIcon";
import { useLogout } from "../hooks/useLogout";

export default function TopSection() {
	const {
		DisplayName,
	} = usePlayer();

	const isHome = useMatch('/');

	const {
		xp,
		points,
	} = useStats();

	const {
		logout,
	} = useLogout();

	return (
		<div className="top-section">
			<div className="bar">
				{!isHome && (<NavLink to='/' className="back-button"><ChevronIcon /> Back</NavLink>)}
				{isHome && (<div className="back-button" onClick={logout}>Logout</div>)}
				<div className="title">UAT Env</div>
				<BellIcon />
			</div>
			<div className="user-profile">
				<div className="card">
					<Avatar />
					<div className="infos">
						<div className="display-name">{DisplayName}</div>
					</div>
				</div>
				<div className="league">
					<div className="league-icon bronze"></div>
					<div className="league-name">
						League<br/>
						<span>Bronze</span>
					</div>
				</div>
			</div>
			<div className="stats">
				<div className="stat">
					<div className="name">League Rank</div>
					<div className="value">
						<TrophyIcon />
						<div className="num">7</div>
					</div>
				</div>
				<div className="stat">
					<div className="name">Points</div>
					<div className="value">
						<StarIcon />
						<div className="num">{points}</div>
					</div>
				</div>
				<div className="stat">
					<div className="name">XP</div>
					<div className="value">
						<CloverIcon />
						<div className="num">{xp}</div>
					</div>
				</div>
			</div>
		</div>
	)
}